<template>
	<div class="">
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 角色管理
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="handle-box" style="margin: 5px 0;">
			<el-button type="primary" class="handle-del mr10" @click="addUser">添加</el-button>
		</div>
		<el-table :data="list" border class="table" ref="multipleTable" header-cell-class-name="table-header">
			<el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
			<el-table-column prop="real_name" label="姓名"></el-table-column>
			<el-table-column prop="mobile" label="手机号"></el-table-column>
			<el-table-column prop="user_name" label="用户名"></el-table-column>
			<el-table-column label="状态">
				<template #default="scope">
					<span v-if="scope.row.status==1" style="color:red;">禁用</span>
					<span v-else style="color:#66b1ff;">启用</span>
				</template>
			</el-table-column>
			<el-table-column label="权限" width="250">
				<template #default="scope">
					<el-space wrap>
						<el-button type="primary" round v-for="(item,index) in scope.row.roles" :key="index">
							{{item.role_name}}</el-button>
					</el-space>
				</template>
			</el-table-column>
			<el-table-column prop="created_at" label="创建时间"></el-table-column>
			<el-table-column prop="updated_at" label="编辑时间"></el-table-column>

			<el-table-column label="操作" width="180" align="center">
				<template #default="scope">
					<el-button type="text" @click="resetPas(scope.row)">重置密码</el-button>
					<el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
					<el-button type="text" style="color:#66b1ff;" v-if="scope.row.status==1"
						@click="updateStatus(scope.row.id)">启用</el-button>
					<el-button type="text" style="color:red;" v-else @click="updateStatus(scope.row.id)">禁用</el-button>
					<el-button type="text" @click="getSelect(scope.row)">分配角色</el-button>
					<el-button type="text" style="color:red;"  @click="deleteUser(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
		</div>

		<!-- 弹出框 -->
		<el-dialog :title="handleType==2?'新增':'编辑'" v-model="handleVisible" width="30%">
			<el-form ref="form" :model="form" label-width="70px">
				<el-form-item label="用户名">
					<el-input v-model="form.user_name"></el-input>
				</el-form-item>
				<el-form-item label="手机号">
					<el-input v-model="form.mobile" maxlength="11"></el-input>
				</el-form-item>
				<el-form-item label="姓名">
					<el-input v-model="form.real_name"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="form.status" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="handleVisible = false">取 消</el-button>
					<el-button type="primary" @click="confirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 角色展示 -->
		<el-dialog title="角色分配" v-model="roleVisible" width="30%">
			<el-form ref="form" label-width="70px">
				<el-form-item>
					<el-checkbox-group v-model="role_ids">
						<el-checkbox v-for="item in checkArr" :label="item.id" :key="item.id">{{item.role_name}}
						</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="roleVisible = false">取 消</el-button>
					<el-button type="primary" @click="roleConfirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
				query: {
					page: 1,
					pageSize: 10
				},
				pageTotal: 10,
				handleVisible: false,
				form: {
					user_name: '',
					mobile: '',
					real_name: '',
					status: ''
				},
				options: [{
						label: '禁用',
						value: 1
					},
					{
						label: '启用',
						value: 0
					}
				],
				// 1编辑2新增
				handleType: 1,
				checkArr: [],
				roleVisible: false,
				role_ids: [],
				user_id: 0
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getList() {
				this.$axios({
					url: 'admin/user/getList',
					method: 'post',
				}).then(({
					code,
					data: {
						data,
						total
					}
				}) => {
					if (code == 200) {
						this.list = data
						this.pageTotal = total
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			handlePageChange(val) {
				this.$set(this.query, 'page', val)
				this.getList()
			},
			// 编辑
			handleEdit(row) {
				let {
					user_name,
					mobile,
					real_name,
					status,
					id
				} = row
				this.form = {
					user_name,
					mobile,
					real_name,
					status,
					id
				}
				this.handleType = 1
				this.handleVisible = true
			},
			// 修改状态
			updateStatus(id) {
				this.$axios({
					url: 'admin/user/updateStatus',
					method: 'get',
					params: {
						id
					}
				}).then(({
					code,
					msg
				}) => {
					if (code == 200) {
						this.$message.success(msg);
						this.getList()
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});

			},
			// 添加用户
			addUser() {
				this.handleVisible = true
				this.handleType = 2
			},
			// 确定
			confirm() {
				let url;
				if (this.handleType == 2) {
					url = 'admin/user/create'
				} else {
					url = 'admin/user/edit'
				}

				this.$axios({
					url,
					method: 'post',
					data: {
						...this.form
					}
				}).then(({
					code,
					msg
				}) => {
					if (code == 200) {
						this.$message.success(msg);
						this.from = {}
						this.handleVisible = false
						this.getList()
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			// 获取角色列表
			getSelect(row) {
				this.user_id = row.id
				this.roleVisible = true

				let roles = row.roles
				let role_ids = []
				for (let i in roles) {
					role_ids.push(roles[i].id)
				}
				this.role_ids = role_ids
				this.$axios({
					url: 'admin/role/getSelect',
					method: 'get',
				}).then(({
					code,
					data
				}) => {
					if (code == 200) {
						console.log(data)
						this.checkArr = data

					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			// 确认分配
			roleConfirm() {
				console.log(this.role_ids)
				this.$axios({
					url: 'admin/user/assignRoles',
					method: 'post',
					data: {
						role_ids: this.role_ids,
						user_id: this.user_id
					}
				}).then(({
					code,
					msg
				}) => {
					if (code == 200) {
						this.roleVisible = false
						this.$message.success(msg)
						this.getList()
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			resetPas(item) {
				this.$confirm('此操作将重置该用户密码, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios({
						url: 'admin/user/originalPwd',
						method: 'get',
						params: {
							id: item.id
						}
					}).then(({
						code,
						msg
					}) => {
						if (code == 200) {
							this.$message({
								type: 'success',
								message: msg
							});
						} else {
							//this.$message.error(res.msg)
						}
					}).catch(err => {
						console.log(err)
					});

				}).catch(() => {

				});
			},
			// 删除用户
			deleteUser(id){
				this.$confirm('确定删除该用户吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios({
						url: 'admin/user/deleteUser',
						method: 'get',
						params: {
							id
						}
					}).then(({
						code,
						msg
						}) => {
							if (code == 200) {
								this.$message.success(msg);
								this.getList()
							} else {
								this.$message.error(msg)
							}
						}).catch(err => {
						console.log(err)
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
			}
		}
	}
</script>

<style scoped>

</style>
